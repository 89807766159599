<template>
  <div class="navigation-list__item" v-click-outside="onDismiss">
    <div
      class="navigation-list__item-label"
      :class="{ 'navigation-list__item-label--highlight': highlight }"
      @click="active = true"
    >
      <template v-if="hasSlot">
        <slot />
      </template>
      <template v-else>
        <router-link :to="to" v-if="to">{{ label }}</router-link>
        <template v-else>{{ label }}</template>
      </template>
    </div>
    <template v-if="items.length">
      <transition name="fade-up">
        <div class="navigation-list__item-menu" v-if="active">
          <router-link
            :to="item.to"
            class="navigation-list__item-menu-item"
            v-for="(item, index) in items"
            :key="`item-${index}`"
            @click.native="active = false"
          >
            <div class="navigation-list__item-menu-item-icon" v-if="item.icon">
              <BaseSVG :src="require(`@/assets/${item.icon}.svg`)" />
            </div>
            <div class="navigation-list__item-menu-item-heading">
              {{ item.label }}
            </div>
            <div class="navigation-list__item-menu-item-description">
              {{ item.description }}
            </div>
          </router-link>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";
export default {
  name: "NavigationListItem",
  props: {
    label: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    BaseSVG,
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    hasSlot() {
      return typeof this.$slots.default != "undefined";
    },
  },
  methods: {
    onDismiss() {
      this.active = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.navigation-list__item {
  display: inline-block;
  margin-right: 28px;
  position: relative;
  cursor: pointer;

  .fade-up-enter-active,
  .fade-up-leave-active {
    transition: opacity 0.425s,
      transform 0.425s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .fade-up-enter,
  .fade-up-leave-to {
    opacity: 0;
    transform: translate3d(0, -16px, 0);
  }
  .fade-up-enter-to {
    transform: translate3d(0, 0, 0);
  }

  &:last-of-type {
    margin-right: 0;
  }

  &-label {
    font-size: 10px;
    font-weight: 800;
    text-transform: uppercase;

    &--highlight {
      color: $green;
    }
  }

  &-menu {
    position: absolute;
    right: 0;
    background: #fff;
    max-height: 512px;
    width: 360px;
    overflow-y: auto;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 4px 4px;
    padding: 12px 0;
    margin-top: 20px;
    border-top: solid 1px $fade-gray;
    z-index: 1;

    &-item {
      display: block;
      border-bottom: solid 1px $fade-gray;
      padding: 16px 24px;
      cursor: pointer;
      position: relative;
      padding-left: 48px;

      &-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        left: 16px;

        svg {
          width: 16px;
          height: auto;
          fill: $medium-gray;
        }
      }

      &-heading {
        color: $gray;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      &-description {
        color: $medium-gray;
        font-size: 14px;
      }

      &:hover {
        background: $fade-gray;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
</style>