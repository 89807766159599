<template>
	<footer class="main-footer">
		<div class="container">
			<img class="icon" src="@/assets/sharemd-icon.svg" />
			<span class="legal">&copy; 2021 ShareMD, Inc. All rights reserved.</span>
			<a class="terms-link">Terms of Use</a>
		</div>
	</footer>
</template>

<script>
export default {
  name: 'MainFooter'
}
</script>

<style lang="scss">
	@import '@/scss/colors';

	.main-footer {
		width: 100%;
		height: 54px;
		background: #FFF;
		box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

		.container {
			display: flex;
			align-items: center;
			height: 100%;

			.icon {
				height: 24px;
				width: auto;
			}

			.legal {
				font-size: 0.75rem;
				margin-left: 2rem;
			}

			.terms-link {
				font-size: 0.75rem;
				margin-left: auto;
				color: $green;
			}
		}
	}
</style>