import axios from "axios";

const state = {
  loading: false,
  views: [],
  campaigns: [],
  campaign: null,
  viewSaving: false,
  selectedContent: null,
  ads: [],
  tags: [],
};
const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_CAMPAIGNS(state, payload) {
    state.campaigns = payload;
  },
  SET_CAMPAIGN(state, payload) {
    state.campaign = payload;
  },
  UPDATE_CAMPAIGN_STATUS(state, payload) {
    let index = state.campaigns.findIndex((campaign) => {
      return campaign.id === payload.id;
    });

    if (index > -1) {
      state.campaigns[index].status = payload.status;
    }
  },
  SET_VIEWS(state, payload) {
    //Ensure meta data is an object, not an empty array
    let views = payload;
    for (let i in views) {
      if (typeof views[i].meta_data.length === "number") {
        views[i].meta_data = {};
      }
    }
    state.views = views;
  },
  ADD_CAMPAIGN(state, payload) {
    state.campaigns.push(payload);
  },
  ADD_VIEW(state, payload) {
    // Add this to the campaign views
    state.views.push(payload);
  },
  ADD_AD(state, payload) {
    // Add this to the campaign views
    state.ads.push(payload);
  },
  REMOVE_VIEW(state, payload) {
    let viewIndex = state.views.findIndex((item) => {
      return item.id === payload.id;
    });

    if (viewIndex > -1) {
      state.views.splice(viewIndex, 1);
    }
  },
  SET_VIEW(state, payload) {
    // Get the view
    let index = state.views.findIndex((item) => {
      return item.id === payload.id;
    });

    if (index !== -1) {
      state.views[index] = payload;
    }
  },
  ADD_COMPONENT(state, payload) {
    // Get the view
    let view = state.views.find((item) => {
      return item.id === payload.view_id;
    });

    if (view) {
      view.components.splice(payload.priority, 0, payload);

      // For each component after this, increment priority by 1
      for (let i = 0; i < view.components.length; i++) {
        view.components[i].priority = i;
      }
    }
  },
  REMOVE_COMPONENT(state, payload) {
    // Get the view
    let view = state.views.find((item) => {
      return item.id === payload.view_id;
    });

    if (view) {
      view.components.splice(payload.priority, 1);

      // For each component after this, increment priority by 1
      for (let i = 0; i < view.components.length; i++) {
        view.components[i].priority = i;
      }
    }
  },
  SET_VIEW_SAVING(state, payload) {
    state.viewSaving = payload;
  },
  SET_ADS(state, payload) {
    state.ads = payload;
  },
  SET_TAGS(state, payload) {
    state.tags = payload;
  },
  SET_SELECTED_CONTENT(state, payload) {
    state.selectedContent = payload;
  }
};
const actions = {
  async getCampaigns(context) {
    context.commit("SET_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/campaigns`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_CAMPAIGNS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  getCampaign(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/campaigns/${payload.campaign_id}`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_CAMPAIGN", res.data);
            context.commit("SET_VIEWS", res.data.views);
            context.commit("SET_ADS", res.data.ads);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addCampaign(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/campaigns`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("ADD_CAMPAIGN", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  saveCampaign(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/campaigns/${payload.id}`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  changeCampaignStatus(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/campaigns/${payload.id}/status`,
          payload
        )
        .then((res) => {
          context.commit("UPDATE_CAMPAIGN_STATUS", payload);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addView(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/views`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("ADD_VIEW", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  updateView(context, payload) {
    context.commit("SET_VIEW_SAVING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/views/${payload.id}`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit('SET_VIEW', res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_VIEW_SAVING", false);
        });
    });
  },
  cloneView(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/views/${payload.id}/duplicate`
        )
        .then((res) => {
          if (res.data) {
            context.commit("ADD_VIEW", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  deleteView(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/views/${payload.id}`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("REMOVE_VIEW", payload);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addComponentToView(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/components`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("ADD_COMPONENT", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  updateViewComponent(context, payload) {
    context.commit("SET_VIEW_SAVING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/components/${payload.id}`,
          payload
        )
        .then((res) => {
          if (res.data) {
            // context.commit('SET_VIEW_COMPONENT', res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_VIEW_SAVING", false);
        });
    });
  },
  updateComponents(context, payload) {
    context.commit("SET_VIEW_SAVING", true);

    return new Promise((resolve, reject) => {
      // Check the content type

      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/views/${payload.id}/components`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_VIEW_SAVING", false);
        });
    });
  },
  removeComponentFromView(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/components/${payload.id}`
        )
        .then((res) => {
          if (res.data) {
            context.commit("REMOVE_COMPONENT", payload);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addAd(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/ads`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("ADD_AD", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  updateAd(context, payload) {
    context.commit("SET_VIEW_SAVING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/ads/${payload.id}`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_VIEW_SAVING", false);
        });
    });
  },
  updateCollection(context, payload) {
    context.commit("SET_VIEW_SAVING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/components/${payload.id}/collections`,
          payload.collection
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_VIEW_SAVING", false);
        });
    });
  },
  getTags(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/tags`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_TAGS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addTags(context, payload) {
    return new Promise((resolve, reject) => {
      const urlSegment =
        payload.type === "lander" || payload.type === "email" ? "views" : "ads";

      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/${urlSegment}/${payload.id}/tags`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setSelectedContent(context, payload) {
    context.commit("SET_SELECTED_CONTENT", payload);
  }
};
const getters = {
  loading: (state) => state.loading,
  campaigns: (state) => state.campaigns,
  campaign: (state) => state.campaign,
  views: (state) => state.views,
  viewSaving: (state) => state.viewSaving,
  selectedContent: (state) => state.selectedContent,
  ads: (state) => state.ads,
  tags: (state) => state.tags,
};

const engageModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default engageModule;
