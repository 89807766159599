<template>
	<InlineSvg :src="src" />
</template>

<script>
	import InlineSvg from 'vue-inline-svg';

	export default {
		name: "BaseSVG",
		props: {
			src: {
				type: String,
				required: true
			}
		},
		components: {
			InlineSvg
		},
		data() {
			return {
				markup: null
			}
		}
	}
</script>

<style lang="scss">

</style>