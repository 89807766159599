<template>
  <nav class="navigation-list">
    <slot />
  </nav>
</template>

<script>
export default {
  name: "NavigationList",
};
</script>

<style lang="scss">
.navigation-list {
  display: inline-block;
}
</style>