var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"main-header"},[_c('div',{staticClass:"container"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/sharemd-logo.svg")}}),_c('NavigationList',[_c('NavigationListItem',{attrs:{"label":'Identify',"to":'/audiences'}}),_c('NavigationListItem',{attrs:{"label":'Engage',"items":[
          {
            icon: 'campaign-icon',
            label: 'Campaigns',
            description: 'Manage campaigns and marketing assets',
            to: '/campaigns',
          },
          {
            icon: 'image-icon',
            label: 'Media Library',
            description: 'Manage images, icons, and animations',
            to: '/media',
          },
          {
            icon: 'document-icon',
            label: 'Content Offers',
            description: 'Manage content used to generate demand',
            to: '/content-offers',
          } ]}}),_c('NavigationListItem',{attrs:{"label":'Activate',"items":[
          {
            icon: 'funnel-icon',
            label: 'Conversions',
            description: 'Lead pipeline and conversion reporting',
            to: '/conversions',
          },
          {
            icon: 'phone-monitoring-icon',
            label: 'Call Monitoring',
            description: 'Review call center activity',
            to: '/calls',
          },
          {
            icon: 'lists-icon',
            label: 'Lists',
            description: 'Manage lead list segments',
            to: '/lists',
          } ]}}),_c('NavigationListItem',{attrs:{"label":'Optimize',"to":'/campaigns'}}),_c('NavigationListItem',{attrs:{"highlight":true}},[(_vm.$auth.isAuthenticated)?_c('a',{on:{"click":_vm.logout}},[_vm._v("Logout")]):_vm._e()])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }