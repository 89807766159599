import axios from 'axios'

const state = {
    audience: null,
    audiences: [],
    loading: false,
    locationOptions: [],
    locations: [],
    conditions: [],
    metrics: null
};
const mutations = {
    SET_AUDIENCE(state, payload) {
        state.audience = payload;
    },
    SET_AUDIENCES(state, payload) {
        state.audiences = payload;
    },
    ADD_AUDIENCE(state, payload) {
        state.audiences.push(payload);
    },
    UPDATE_AUDIENCE(state, payload) {
        let index = state.audiences.findIndex((audience) => {
            return audience.id == payload.id;
        });

        state.audiences.splice(index, 1, payload);
    },
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
    SET_LOCATION_OPTIONS(state, payload) {
        state.locationOptions = payload;
    },
    SET_LOCATIONS(state, payload) {
        state.locations = payload;
    },
    SET_CONDITIONS(state, payload) {
        state.conditions = payload;
    },
    SET_DEMOGRAPHICS(state, payload) {
        if (state.audience && payload?.counties) {
            if (payload?.counties) {
                state.audience.demographics = payload.counties;
            }

            if (payload?.metrics) {
                state.metrics = payload.metrics;
            }
        }
    }
};
const actions = {
    setSelectedAudience(context, payload) {
        context.commit('SET_AUDIENCE', payload);
    },
    getAudience(context, payload) {
        context.commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/audiences/${payload.id}`)
                .then((res) => {
                    if (res.status < 300) {
                        context.commit('SET_AUDIENCE', res.data);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_LOADING', false);
                })
        })
    },
    getAudiences(context) {
        context.commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/audiences`)
                .then((res) => {
                    if (res.data) {
                        context.commit('SET_AUDIENCES', res.data);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_LOADING', false);
                })
        })
    },
    saveAudience(context, payload) {
        context.commit('SET_LOADING', true);
        let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/audiences`;
        let action = 'post';
        let mutation = 'ADD_AUDIENCE';

        if (payload.id) {
            url += `/${payload.id}`;
            action = 'put';
            mutation = 'UPDATE_AUDIENCE';
        }

        return new Promise((resolve, reject) => {
            axios[action](url, payload)
                .then((res) => {
                    if (res.status < 300) {
                        context.commit(mutation, res.data);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_LOADING', false);
                })
        })
    },
    getConditions(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/service/audiences/condition-options`)
                .then((res) => {
                    if (res.status < 300) {
                        context.commit('SET_CONDITIONS', res.data);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                });
        })
    },
    searchLocations(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/service/audiences/search-locations?search_term=${payload.term}`
                )
                .then((res) => {
                    let options = res.data.map((item) => {
                        return {
                            name: `${item.county}, ${item.state}`,
                            value: item.id,
                        };
                    });
                    context.commit('SET_LOCATION_OPTIONS', options);
                    resolve(options);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    onGetLocations(context, payload) {
        context.commit('SET_LOADING', true);

        let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/service/audiences/get-locations?county_id=${payload.location}&radius=${payload.radius}`;

        if (payload.state) {
            url += `&state=${payload.state}`;
        }

        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    context.commit('SET_LOCATIONS', res.data);
                    resolve(res);
                })
                .catch((e) => {
                    reject(e);
                })
                .then(() => {
                    context.commit('SET_LOADING', false);
                });
        });
    },
    onGetDemographics(context, payload) {
        let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/service/audiences/condition-demographics?condition_id=${payload.condition}&counties=${payload.counties}`;

        if (payload.access.length) {
            const accessList = payload.access.join(",");
            url += `&access=${accessList}`;
        }

        if (payload.environment.length) {
            const environmentList = payload.environment.join(",");
            url += `&environment=${environmentList}`;
        }

        context.commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    context.commit('SET_DEMOGRAPHICS', res.data);
                    resolve(res);
                })
                .catch((e) => {
                    reject(e);
                })
                .then(() => {
                    context.commit('SET_LOADING', false);
                });
        });
    }
};
const getters = {
    audience: state => state.audience,
    audiences: state => state.audiences,
    loading: state => state.loading,
    locationOptions: state => state.locationOptions,
    locations: state => state.locations,
    conditions: state => state.conditions,
    metrics: state => state.metrics
};

const audiencesModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default audiencesModule;