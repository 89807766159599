import axios from 'axios'

const state = {
    audiences: [],
    audienceLoading: false,
    loading: false,
    locationsLoading: false,
    locations: [],
    locationSearchResults: [],
    places: [],
    placesLoading: false,
    zipCodes: [],
    zipCodesLoading: false,
    zipCodeSelected: null,
    demographics: [],
    demographicsLookup: {},
    specialty: null,
    icd10: null,
    engagementRate: 0.2,
    activationRate: 0.025,
    audienceThreshold: 0.01,
    costPerActivation: 379,
    payerMix: 0
};
const mutations = {
    SET_AUDIENCES(state, payload) {
        state.audiences = payload;
    },
    ADD_AUDIENCE(state, payload) {
        state.audiences.push(payload);
    },
    SET_AUDIENCE_LOADING: function (state, payload) {
        state.audienceLoading = payload;
    },
    SET_LOADING: function (state, payload) {
        state.loading = payload;
    },
    SET_LOCATIONS_LOADING: function (state, payload) {
        state.locationsLoading = payload;
    },
    SET_LOCATIONS: function (state, payload) {
        if (payload) {
            state.locations = payload;
        }
    },
    ADD_LOCATIONS: function (state, payload) {
        if (payload) {
            state.locations.push(...payload);
        }
    },
    CLEAR_LOCATIONS: function (state) {
        state.locations.splice(0);
    },
    SET_PLACES: function (state, payload) {
        if (payload) {
            state.places = payload;
        }
    },
    SET_PLACES_LOADING: function (state, payload) {
        state.placesLoading = payload;
    },
    SET_ZIP_CODES: function (state, payload) {
        if (payload) {
            state.zipCodes = payload;
        }
    },
    SET_ZIP_CODES_LOADING: function (state, payload) {
        state.zipCodesLoading = payload;
    },
    SET_ZIP_CODE_SELECTED: function (state, payload) {
        state.zipCodeSelected = payload;
    },
    SET_DEMOGRAPHICS: function (state, payload) {
        state.demographics = payload;
    },
    SET_LOCATION_SEARCH_RESULTS: function (state, payload) {
        state.locationSearchResults = payload;
    },
    SET_SPECIALTY(state, payload) {
        state.specialty = payload;
    },
    SET_ICD10: function (state, payload) {
        state.icd10 = payload;
    },
    ADD_LOCATION: function (state, payload) {
        state.locations.push(payload);
    },
    SET_ENGAGEMENT_RATE: function (state, payload) {
        state.engagementRate = payload;
    },
    SET_ACTIVATION_RATE: function (state, payload) {
        state.activationRate = payload;
    },
    SET_AUDIENCE_THRESHOLD: function (state, payload) {
        state.audienceThreshold = payload;
    },
    SET_PAYER_MIX: function (state, payload) {
        state.payerMix = payload;
    },
    REMOVE_LOCATION: function (state, payload) {
        let i = state.locations.indexOf(payload);
        if (i > -1) {
            state.locations.splice(i, 1);
        }
    }
};
const actions = {
    getAudiences(context) {
        context.commit('SET_AUDIENCE_LOADING', true);
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/audiences`)
                .then((res) => {
                    if (res.data) {
                        context.commit('SET_AUDIENCES', res.data);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_AUDIENCE_LOADING', false);
                })
        })
    },
    addAudience(context, payload) {
        context.commit('SET_AUDIENCE_LOADING', true);
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/audiences`, payload)
                .then((res) => {
                    if (res.status < 300) {
                        context.commit('ADD_AUDIENCE', res.data);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_AUDIENCE_LOADING', false);
                })
        })
    },
    searchLocations(context, payload) {
        context.commit('SET_LOCATIONS_LOADING', true);
        return new Promise((resolve, reject) => {
            axios.get(`https://identification.sharemd.com/places/search?search_term=${payload.searchTerm}`)
                .then((res) => {
                    if (res.status < 300) {
                        context.commit('SET_LOCATION_SEARCH_RESULTS', res.data);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_LOCATIONS_LOADING', false);
                })
        })
    },
    clearLocations(context) {
        context.commit('CLEAR_LOCATIONS');
    },
    searchDemographicData(context, payload) {
        context.commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            axios.get(`https://identification.sharemd.com/places?places=${payload.locationIds}&icd_10=${payload.icd10}`)
                .then((res) => {
                    if (res.data) {
                        context.commit('SET_SPECIALTY', payload.specialty.toLowerCase());
                        context.commit('SET_ICD10', payload.icd10)
                        context.commit('SET_DEMOGRAPHICS', res.data);
                        // Set initial payerMix
                        let totalPrivatePay = 0;
                        let totalPublicPay = 0;

                        res.data.map((location) => {
                            totalPrivatePay += location.total_private_pay;
                            totalPublicPay += location.total_public_pay;
                        });

                        console.log(payload);

                        context.commit('SET_PAYER_MIX', totalPrivatePay / (totalPrivatePay + totalPublicPay));
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_LOADING', false);
                })
        })
    },
    searchPlaces(context, payload) {
        context.commit('SET_LOADING', true);
        const radius = (payload.radius) ? payload.radius : 30;

        return new Promise((resolve, reject) => {
            axios.get(`https://identification.sharemd.com/places/searchByRadius?places=${payload.locationIds}&radius=${radius}`)
                .then((res) => {
                    if (res.status < 300) {
                        context.commit('ADD_LOCATIONS', res.data);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_LOADING', false);
                })
        })
    },
    searchZipCodes(context, payload) {
        context.commit('SET_ZIP_CODES_LOADING', true);

        return new Promise((resolve, reject) => {
            axios.get(`https://identification.sharemd.com/zip_codes_by_county?ids=${payload.ids}`)
                .then((res) => {
                    if (res.status < 300) {
                        context.commit('SET_ZIP_CODES', res.data);
                        if (res.data.length) {
                            context.commit('SET_ZIP_CODE_SELECTED', res.data[0]);
                        }
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_ZIP_CODES_LOADING', false);
                })
        })
    },
    setLocationSearchResults: function (context, payload) {
        context.commit('SET_LOCATION_SEARCH_RESULTS', payload);
    },
    setSpecialty: function (context, payload) {
        context.commit('SET_SPECIALTY', payload);
    },
    setIcd10Code: function (context, payload) {
        context.commit('SET_ICD10', payload);
    },
    addLocation: function (context, payload) {
        context.commit('ADD_LOCATION', payload);
    },
    setActivationRate: function (context, payload) {
        context.commit('SET_ACTIVATION_RATE', payload);
    },
    setEngagementRate: function (context, payload) {
        context.commit('SET_ENGAGEMENT_RATE', payload);
    },
    setAudienceThreshold: function (context, payload) {
        context.commit('SET_AUDIENCE_THRESHOLD', payload);
    },
    setPayerMix: function (context, payload) {
        context.commit('SET_PAYER_MIX', payload);
    },
    removeLocation: function (context, payload) {
        if (payload.location) {
            context.commit('REMOVE_LOCATION', payload.location);
        }
    },
    setZipCodeSelected: function (context, payload) {
        context.commit('SET_ZIP_CODE_SELECTED', payload);
    }
};
const getters = {
    audiences: state => state.audiences,
    audienceLoading: state => state.audienceLoading,
    loading: state => state.loading,
    locationsLoading: state => state.locationsLoading,
    locations: state => state.locations,
    locationSearchResults: state => state.locationSearchResults,
    places: state => state.places,
    placesLoading: state => state.placesLoading,
    zipCodes: state => state.zipCodes,
    zipCodesLoading: state => state.zipCodesLoading,
    zipCodeSelected: state => state.zipCodeSelected,
    demographics: state => state.demographics,
    specialty: state => state.specialty,
    icd10: state => state.icd10,
    engagementRate: state => state.engagementRate,
    activationRate: state => state.activationRate,
    audienceThreshold: state => state.audienceThreshold,
    costPerActivation: state => state.costPerActivation,
    payerMix: state => state.payerMix
};

const identificationModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default identificationModule;