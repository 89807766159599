import axios from "axios";

const state = {
  media: [],
  loading: false,
};
const mutations = {
  SET_LOADING: function(state, payload) {
    state.loading = payload;
  },
  SET_MEDIA: function(state, payload) {
    state.media = payload;
  },
};
const actions = {
  getMedia(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/media`;
      if (payload && payload.filter) {
        url += "?filter=" + payload.filter;
      }
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            context.commit("SET_MEDIA", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addMedia(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/media`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  updateMedia(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${
            process.env.VUE_APP_API_PATH
          }/media/${payload.get("id")}`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
};
const getters = {
  media: (state) => state.media,
  loading: (state) => state.loading,
};

const mediaModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default mediaModule;
