import Vue from "vue";
import App from "./App.vue";
import Chart from "chart.js";
import store from "./store";
import router from "./router";
import "./filters";
import * as VueGoogleMaps from "vue2-google-maps";
import { Auth0Plugin, getInstance } from "./auth";
//import { domain, clientId, audience } from "./auth_config.json";
import Axios from "axios";
import lazyimage from "@/directives/lazyimage";
import clickOutside from "@/directives/click_outside";
import FlashMessage from "@smartweb/vue-flash-message";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import FlowyPlugin from "@hipsjs/flowy-vue";

Vue.directive("lazyimage", lazyimage);
Vue.directive("click-outside", clickOutside);

// Install the authentication plugin
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

//Use Flowy Plugin for flowcharts
Vue.use(FlowyPlugin);

// Set the access token for all requests
Vue.prototype.$http = Axios;
const instance = getInstance();
instance.$watch("loading", async (loading) => {
  if (!loading && instance.isAuthenticated) {
    const token = await instance.getTokenSilently();
    Vue.prototype.$http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  }
});

Vue.config.productionTip = false;

// Set the chart globals
Chart.defaults.global.defaultFontFamily = "Nunito Sans";
Chart.defaults.doughnut.cutoutPercentage = 88;
Chart.defaults.bar.barThickness = 12;
Chart.defaults.global.tooltips.backgroundColor = "rgba(255, 255, 255, 0.95)";
Chart.defaults.global.tooltips.titleFontSize = 12;
Chart.defaults.global.tooltips.titleFontColor = "#233267";
Chart.defaults.global.tooltips.bodyFontColor = "#343434";
Chart.defaults.global.tooltips.borderColor = "rgba(240,240,240,1)";
Chart.defaults.global.tooltips.borderWidth = 1;
Chart.defaults.global.tooltips.xPadding = 12;
Chart.defaults.global.tooltips.yPadding = 12;
Chart.defaults.global.tooltips.displayColors = false;
Chart.defaults.global.plugins.legend = false;

// Setup Google Maps
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDb2r5EGR8OsyFKUTpeJh61BUh2xVDwrRA",
  },
});

Vue.use(FlashMessage);

Sentry.init({
  Vue: Vue,
  dsn: "https://d26107213f494e11adf42c7062aaedec@o913417.ingest.sentry.io/5851407",
  integrations: [new Integrations.BrowserTracing()],
});

// Create a global event bus
// !! USE WITH CAUTION !!
Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount("#app");
