<template>
  <div id="app">
    <loading
      :active="!$auth.isAuthenticated"
      :fullscreen="true"
      :opaque="true"
    />

    <MainHeader />
    <router-view class="view-wrapper" />
    <MainFooter />

    <FlashMessage :position="'right top'"></FlashMessage>
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";
import MainHeader from "./components/MainHeader.vue";
import MainFooter from "./components/MainFooter.vue";

export default {
  name: "app",
  components: {
    Loading,
    MainHeader,
    MainFooter,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,600;0,800;1,400&display=swap");
@import "@/scss/reset";
@import "@/scss/colors";
@import "@/scss/container";

html,
body {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  min-width: 1234px;
}

* {
  box-sizing: border-box;
}

strong {
  font-weight: 900;
}

em {
  font-style: italic;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.425s,
    transform 0.425s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.fade-up-enter,
.fade-up-leave-to {
  opacity: 0;
  transform: translate3d(0, 64px, 0);
}
.fade-up-enter-to {
  transform: translate3d(0, 0, 0);
}

.slide-in-right-enter-active,
.slide-in-right-leave-active {
  transition: opacity 0.425s,
    transform 0.425s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.slide-in-right-enter,
.slide-in-right-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.slide-in-right-enter-to {
  transform: translateX(0);
}
/*End Transitions*/

#app {
  display: block;
  width: 100%;
  height: 100%;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: $gray;

  h2 {
    font-size: 1rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    button {
      margin-left: auto;
    }

    .saving-feedback {
      margin-left: auto;
      font-size: 14px;
      color: $medium-gray;
    }
  }

  .view-wrapper {
    min-height: calc(100% - 54px - 54px);
    padding: 2rem 0;
  }

  .card {
    background: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 8px;

    h2 {
      font-size: 1rem;
      font-weight: 600;
    }

    & .card {
      padding: 0 0 2rem 0;
      box-shadow: none;
      border-bottom: solid 1px $fade-gray;
      margin-bottom: 2rem;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &.highlight {
      background: $dark-blue;
      /*background: linear-gradient(315deg, $blue 0%, $light-blue 100%);*/
      color: #fff;
      &.light {
        background: $light-blue;
      }
    }
  }

  .empty-view-wrapper {
    text-align: center;
    border: dashed 3px $fade-gray;
    padding: 2rem;
    border-radius: 8px;

    button {
      margin-top: 1rem;
    }

    p {
      font-weight: 600;
      color: $medium-gray;
    }
  }

  .vue-map-container {
    width: 100%;
    height: 100%;
  }
}

._vue-flash-msg-body {
  z-index: 1000;
}
._vue-flash-msg-body__content {
  p {
    margin: 1em 0;

    &:first-child {
      font-weight: bold;
    }
  }
}
</style>
