import axios from "axios";

const state = {
  loading: false,
  events: [],
};
const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_EVENTS(state, payload) {
    state.events = payload;
  },
};
const actions = {
  getEvents(context) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/events`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_EVENTS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
};
const getters = {
  loading: (state) => state.loading,
  events: (state) => state.events,
};

const activateModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default activateModule;
