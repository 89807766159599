import Vue from "vue";
import Vuex from "vuex";
import identification from "./modules/identification";
import audiences from "./modules/audiences";
import engage from "./modules/engage";
import media from "./modules/media";
import optimize from "./modules/optimize";
import activate from "./modules/activate";
import conversions from "./modules/conversions";
import contentOffers from "./modules/content-offers";
import providers from "./modules/providers";
import events from "./modules/events";
import lists from "./modules/lists";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    identification,
    audiences,
    engage,
    media,
    optimize,
    activate,
    conversions,
    contentOffers,
    events,
    providers,
    lists,
  },
});
