import axios from 'axios'

const state = {
    loading: false,
    assessments: []
};
const mutations = {
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
    SET_ASSESSMENTS(state, payload) {
        state.assessments = payload;
    }
};
const actions = {
    getAssessments(context) {
        context.commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/assessments`)
                .then((res) => {
                    if (res.data) {
                        context.commit('SET_ASSESSMENTS', res.data);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_LOADING', false);
                })
        });
    }
};
const getters = {
    loading: state => state.loading,
    assessments: state => state.assessments
};

const activateModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default activateModule;