import axios from "axios";

const state = {
  loading: false,
  conditions: [],
  views: [],
  offers: [],
  offer: null,
};

const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_CONDITIONS(state, payload) {
    state.conditions = payload;
  },
  SET_VIEWS(state, payload) {
    state.views = payload;
  },
  SET_OFFERS(state, payload) {
    state.offers = payload;
  },
  SET_OFFER(state, payload) {
    state.offer = payload;
  },
};

const actions = {
  getConditions(context) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/conditions`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_CONDITIONS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  getViews(context) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/views`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_VIEWS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  getOffers(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/content_offers?`;
      if (payload && payload.filter) {
        url += "filter=" + payload.filter + "&";
      }
      if (payload && payload.condition && payload.condition !== "") {
        url += "condition=" + payload.condition;
      }
      if (payload && payload.campaign) {
        url += "campaign=" + payload.campaign;
      }
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            context.commit("SET_OFFERS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  getOffer(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/content_offers/${payload.contentOfferId}`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_OFFER", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addOffer(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/content_offers`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_OFFER", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  updateOffer(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/content_offers/${payload.id}`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_OFFER", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  deleteOffer(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/content_offers/${payload.contentOfferId}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
};
const getters = {
  loading: (state) => state.loading,
  conditions: (state) => state.conditions,
  views: (state) => state.views,
  offers: (state) => state.offers,
  offer: (state) => state.offer,
};

const contentOfferModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default contentOfferModule;
