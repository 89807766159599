import axios from 'axios'

const state = {
    ads: [],
    campaign: null,
    loading: false,
    geo: [],
    views: []
};
const mutations = {
    SET_ADS(state, payload) {
        state.ads = payload;
    },
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
    SET_CAMPAIGN(state, payload) {
        state.campaign = payload;
    },
    SET_VIEWS(state, payload) {
        state.views = payload;
    },
    SET_GEO(state, payload) {
        state.geo = payload;
    }
};
const actions = {
    getCampaign(context, payload) {
        context.commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/campaigns/${payload.id}/report?range=${payload.range}`)
                .then((res) => {
                    if (res.data) {
                        context.commit('SET_CAMPAIGN', res?.data);
                        context.commit('SET_VIEWS', res?.data?.views);
                        context.commit('SET_ADS', res?.data?.ads);
                        context.commit('SET_GEO', res?.data?.geography);
                    }

                    resolve(res);
                })
                .catch((err) => {
                    reject(err)
                })
                .then(() => {
                    context.commit('SET_LOADING', false);
                })
        })
    }
};
const getters = {
    ads: state => state.ads,
    campaign: state => state.campaign,
    loading: state => state.loading,
    views: state => state.views,
    geo: state => state.geo
};

const optimizeModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default optimizeModule;