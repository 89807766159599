import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "./auth/authGuard";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "campaigns",
    },
    {
      path: "/audiences",
      name: "audiences",
      component: () => import("./views/Audiences.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/audiences/:id",
      name: "audience",
      component: () => import("./views/Audience.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/providers",
      name: "providers",
      component: () => import("./views/Providers.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/campaigns",
      name: "campaigns",
      component: () => import("./views/Campaigns.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/campaigns/:id",
      name: "campaign",
      component: () => import("./views/Campaign.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/campaigns/:id/report",
      name: "campaign-report",
      component: () => import("./views/CampaignReport.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/campaigns/:id/overview",
      name: "campaign-overview",
      component: () => import("./views/CampaignOverview.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/campaigns/:id/conversions",
      name: "campaign-conversions",
      component: () => import("./views/Conversions.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/conversions",
      name: "conversions",
      component: () => import("./views/Conversions.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/conversions/:id",
      name: "conversion",
      component: () => import("./views/Conversion.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/content-offers",
      name: "content-offers",
      component: () => import("./views/ContentOffers.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/media",
      name: "media",
      component: () => import("./views/Media.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/calls",
      name: "calls",
      component: () => import("./views/Calls.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/lists",
      name: "lists",
      component: () => import("./views/Lists.vue"),
      beforeEnter: authGuard,
    },
  ],
});

export default router;
