<template>
  <transition name="fade">
    <div
      class="loading"
      v-if="active"
      :class="{
        fullscreen,
        'loading--opaque': opaque,
        'loading--small': small,
      }"
    >
      <div class="spinner"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Loading",
  props: ["active", "fullscreen", "opaque", "small"],
};
</script>

<style lang="scss">
@import "@/scss/colors";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.425s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &.fullscreen {
    position: fixed;
  }

  &--opaque {
    background-color: rgba(255, 255, 255, 1);
  }

  .spinner {
    width: 80px;
    height: 80px;
    border-radius: 50%;

    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 4px solid $light-blue;
      border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
        $light-blue;
      animation: spinner 1s linear infinite;
    }
  }

  &--small {
    .spinner {
      width: 40px;
      height: 40px;

      &:after {
        width: 32px;
        height: 32px;
        border-width: 2px;
        margin: 4px;
      }
    }
  }
}
</style>