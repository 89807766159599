import Vue from "vue";
import numeral from "numeral";
import voca from "voca";
import { format, formatDistance, parseISO } from "date-fns";

// Create global filters
Vue.filter("valueOrDash", function (val) {
  if (typeof val == "undefined" || val === null) {
    return "-";
  }

  return val;
});

Vue.filter("currency", function (val) {
  if (val) {
    return numeral(val).format("$0,0");
  }

  return numeral(0).format("$0,0");
});

Vue.filter("number", function (val) {
  if (val) {
    return numeral(val).format("0,0");
  }

  return 0;
});

Vue.filter("numberAbbr", function (val) {
  if (val) {
    return numeral(val).format("0a");
  }

  return 0;
});

Vue.filter("percent", function (val) {
  if (val) {
    return numeral(val).format("0.00%");
  }

  return "0%";
});

Vue.filter("titleCase", function (val) {
  val = val.replace(/[_]+/g, " ");
  return voca.titleCase(val, ["_"]);
});

Vue.filter("date", function (val) {
  return format(new Date(val), "M/d/y");
});

Vue.filter("timeDifferenceInWords", (val) => {
  const today = new Date();
  return voca.titleCase(`${formatDistance(parseISO(val), today)} ago`);
});

Vue.filter("truncate", function (val, len) {
  len = len || 40;
  return voca.truncate(val, len, "...");
});

Vue.filter("dateCell", (val) => {
  let dt = new Date(val);
  return dt.getDate();
});

Vue.filter("leadGender", (val) => {
  if (val === "f") {
    return "Female";
  }

  if (val === "m") {
    return "Male";
  }

  return "Unknown";
});

Vue.filter("leadAge", (val) => {
  const year = parseInt(val);

  if (isNaN(year)) {
    return "Unknown";
  }

  return new Date().getFullYear() - val;
});

Vue.filter("typeFilter", (value) => {
  let type = "";
  switch (value) {
    case "lead_created_raw":
      type = "Raw";
      break;
    case "lead_created_phone":
      type = "Inbound";
      break;
    case "lead_created":
      type = "Qualified";
      break;
    case "lead_created_assessment":
      type = "Assessment";
      break;
    case "general_contact":
      type = "General Contact";
      break;
    case "clone":
      type = "Clone";
      break;

    default:
      type = "Unknown";
      break;
  }

  return type;
});
