import axios from "axios";

const state = {
  loading: false,
  providers: [],
};
const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_PROVIDERS(state, payload) {
    state.providers = payload;
  },
};
const actions = {
  getProviders(context) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/providers`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_PROVIDERS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addLocation(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/locations`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  saveLocation(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/locations/${payload.id}`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addProvider(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/providers`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  saveProvider(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/providers/${payload.id}`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
};
const getters = {
  loading: (state) => state.loading,
  providers: (state) => state.providers,
};

const activateModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default activateModule;
