<template>
  <header class="main-header">
    <div class="container">
      <img class="logo" src="@/assets/sharemd-logo.svg" />
      <NavigationList>
        <NavigationListItem :label="'Identify'" :to="'/audiences'" />
        <NavigationListItem
          :label="'Engage'"
          :items="[
            {
              icon: 'campaign-icon',
              label: 'Campaigns',
              description: 'Manage campaigns and marketing assets',
              to: '/campaigns',
            },
            {
              icon: 'image-icon',
              label: 'Media Library',
              description: 'Manage images, icons, and animations',
              to: '/media',
            },
            {
              icon: 'document-icon',
              label: 'Content Offers',
              description: 'Manage content used to generate demand',
              to: '/content-offers',
            },
          ]"
        />
        <NavigationListItem
          :label="'Activate'"
          :items="[
            {
              icon: 'funnel-icon',
              label: 'Conversions',
              description: 'Lead pipeline and conversion reporting',
              to: '/conversions',
            },
            {
              icon: 'phone-monitoring-icon',
              label: 'Call Monitoring',
              description: 'Review call center activity',
              to: '/calls',
            },
            {
              icon: 'lists-icon',
              label: 'Lists',
              description: 'Manage lead list segments',
              to: '/lists',
            },
          ]"
        />
        <NavigationListItem :label="'Optimize'" :to="'/campaigns'" />
        <NavigationListItem :highlight="true">
          <a v-if="$auth.isAuthenticated" @click="logout">Logout</a>
        </NavigationListItem>
      </NavigationList>
    </div>
  </header>
</template>

<script>
import NavigationList from "@/components/navigation/NavigationList";
import NavigationListItem from "@/components/navigation/NavigationListItem";

export default {
  name: "MainHeader",
  components: {
    NavigationList,
    NavigationListItem,
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.main-header {
  width: 100%;
  height: 54px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .fade-up-enter-active,
  .fade-up-leave-active {
    transition: opacity 0.425s,
      transform 0.425s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .fade-up-enter,
  .fade-up-leave-to {
    opacity: 0;
    transform: translate3d(0, -16px, 0);
  }
  .fade-up-enter-to {
    transform: translate3d(0, 0, 0);
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;

    .logo {
      height: 18px;
      width: auto;
    }

    .navigation-list {
      margin-left: auto;
    }
  }
}
</style>