import axios from "axios";

const state = {
  leads: [],
  lists: [],
  loading: false,
};
const mutations = {
  SET_LOADING: function (state, payload) {
    state.loading = payload;
  },
  SET_LEADS: function (state, payload) {
    state.leads = payload;
  },
  SET_LISTS: function (state, payload) {
    state.lists = payload;
  },
};
const actions = {
  getLists(context) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/lists`;
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            context.commit("SET_LISTS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addList(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/lists`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  updateList(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/lists/${payload.id}`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  refreshList(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/lists/${payload.id}/refresh`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  getLeads(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/lists/${payload.id}/leads`;
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            context.commit("SET_LEADS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  updateLeadLists(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/leads/${payload.lead_id}/lists`;
      axios
        .post(url, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addMessageList(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/lists/${payload.list_id}/messages`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  deleteMessageList(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/message-lists/${payload.id}`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
};
const getters = {
  leads: (state) => state.leads,
  lists: (state) => state.lists,
  loading: (state) => state.loading,
};

const listModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default listModule;
