import axios from "axios";

const state = {
  conversion: null,
  conversions: [],
  campaigns: [],
  campaign: null,
  loading: false,
  providers: [],
  tracking_events: [],
  review_loading: false,
  review: null,
  calls: [],
};
const mutations = {
  SET_CAMPAIGNS(state, payload) {
    state.campaigns = payload;
  },
  SET_PROVIDERS(state, payload) {
    state.providers = payload;
  },
  SET_TRACKING_EVENTS(state, payload) {
    state.tracking_events = payload;
  },
  SET_CAMPAIGN(state, payload) {
    state.campaign = payload;
  },
  SET_CONVERSIONS(state, payload) {
    state.conversions = payload;
  },
  SET_CONVERSION(state, payload) {
    state.conversion = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  ADD_CALL_NOTE(state, payload) {
    // Get the conversion action
    const conversionAction = state.conversion.actions.find((action) => {
      return action.id === payload.conversion_action_id;
    });

    if (typeof conversionAction != "undefined") {
      conversionAction.notes.push(payload);
    }
  },
  SET_REVIEW_LOADING(state, payload) {
    state.review_loading = payload;
  },
  SET_REVIEW(state, payload) {
    state.review = payload;
  },
  SET_CALLS(state, payload) {
    state.calls = payload;
  },
};
const actions = {
  async getReportingData(context) {
    context.commit("SET_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/conversions/reporting_data`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_CAMPAIGNS", res.data.campaigns);
            context.commit("SET_PROVIDERS", res.data.providers);
            context.commit("SET_TRACKING_EVENTS", res.data.tracking_events);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  getConversions(context, payload) {
    context.commit("SET_LOADING", true);
    let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/conversions/search?`;
    if (payload.campaign_id) {
      url += `campaign=${payload.campaign_id}`;
    }
    if (payload.provider_id) {
      url += `&provider=${payload.provider_id}`;
    }
    if (payload.range) {
      url += `&range=${payload.range}`;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            context.commit("SET_CONVERSIONS", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  getConversion(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/conversions/${payload.conversionId}`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_CONVERSION", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addConversion(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/manual-conversion`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_CONVERSION", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  updateConversion(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/conversions/${payload.id}`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_CONVERSION", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  addCallNote(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/call-notes`,
          payload
        )
        .then((res) => {
          if (res.data) {
            context.commit("ADD_CALL_NOTE", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  getConversionActionReview(context, payload) {
    context.commit("SET_REVIEW_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/conversion-actions/${payload.id}/review`
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_REVIEW", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_REVIEW_LOADING", false);
        });
    });
  },
  saveConversionActionReview(context, payload) {
    context.commit("SET_REVIEW_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/conversion-actions/${payload.id}/review`,
          payload.review
        )
        .then((res) => {
          if (res.data) {
            context.commit("SET_REVIEW", res.data);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_REVIEW_LOADING", false);
        });
    });
  },
  getCalls(context, payload) {
    context.commit("SET_LOADING", true);
    let url = `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/conversion-actions/calls?`;
    if (payload.campaign_id) {
      url += `campaign=${payload.campaign_id}`;
    }
    if (payload.provider_id) {
      url += `&provider=${payload.provider_id}`;
    }
    if (payload.nurse_id) {
      url += `&nurse=${payload.nurse_id}`;
    }
    if (payload.range) {
      url += `&range=${payload.range}`;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            context.commit("SET_CALLS", res.data);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  updateConversionAction(context, payload) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/conversion-actions/${payload.id}`,
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
};
const getters = {
  conversion: (state) => state.conversion,
  conversions: (state) => state.conversions,
  campaigns: (state) => state.campaigns,
  campaign: (state) => state.campaign,
  loading: (state) => state.loading,
  providers: (state) => state.providers,
  tracking_events: (state) => state.tracking_events,
  review_loading: (state) => state.review_loading,
  review: (state) => state.review,
  calls: (state) => state.calls,
};

const conversionsModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default conversionsModule;
